<template>
  <div>
    <v-col
      v-if="isToShowImageList"
      class="mt-6"
    >
      <v-row
        v-if="!isLoadingProfile"
        class="mb-2"
        align-content="center"
      >
        <v-icon
          class="mr-2"
          color="textBlack"
          :size="$vuetify.breakpoint.mobile? 20 : 24"
        >
          {{ icons.imageMultiple }}
        </v-icon>
        <div class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text">
          {{ $t('deconve.notification.profileImages') }}
        </div>
      </v-row>

      <v-row
        v-else
        class="mb-2"
      >
        <v-skeleton-loader
          type="heading"
          style="flex: 1"
          max-width="500"
        />
      </v-row>

      <image-list
        :is-edit-mode="false"
        :score-status="true"
        :is-loading="isLoadingProfile"
        :images="personImages"
        @set-crop-image="selectProfileImage"
      />
    </v-col>

    <v-col
      v-if="isToShowSensitiveInformation"
      class="mt-6"
    >
      <v-row
        v-if="!isLoadingProfile"
        class="mb-n2"
      >
        <h5
          v-if="personName"
          class="text-h6 text-sm-h5 font-weight-bold textBlack--text"
          data-dd-privacy="mask"
        >
          {{ personName }}
        </h5>
        <h5
          v-else-if="
            personDisplayStatus === PERSON_NOTIFICATION.isNotToShowPersonProfile"
          class="text-h6 text-sm-h5 font-weight-bold primary--text"
        >
          {{ $t('deconve.collaborativeDatabaseProfile') }}
        </h5>
        <h5
          v-else-if="
            personDisplayStatus === PERSON_NOTIFICATION.isAInvalidPersonProfile"
          class="text-h6 text-sm-h5 font-weight-bold error--text"
          data-dd-privacy="mask"
        >
          {{ $t('deconve.idNotFound', [personId]) }}
        </h5>
      </v-row>

      <v-row
        v-else
        class="mb-2"
      >
        <v-skeleton-loader
          type="heading"
          style="flex: 1"
          max-width="500"
        />
      </v-row>

      <v-row class="mb-4">
        <div class="d-flex flex-wrap">
          <tag
            v-for="tag in personTags"
            :key="tag.id"
            class="pa-1"
            :tag-id="tag.id"
            link="faceidnotifications"
          />
        </div>
      </v-row>

      <v-row
        v-if="personName && personAbout"
        class="mb-2 neutral--text body-2 text-sm-body-1"
      >
        {{ personAbout }}
      </v-row>

      <v-row
        v-if="isLoadingProfile"
        class="mb-2"
      >
        <v-skeleton-loader
          type="paragraph"
          style="flex: 1"
          max-width="500"
        />
      </v-row>

      <v-row>
        <rectangle-button
          v-if="!isLoadingProfile"
          color="neutral"
          outlined
          :disabled="!$can('read', 'com.deconve.faceid.person') || !person"
          @clicked="goToProfile"
        >
          {{ $t('deconve.notification.seeProfile') }}
        </rectangle-button>
        <v-skeleton-loader
          v-else
          type="chip"
          tile
        />
      </v-row>
    </v-col>
  </div>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import ImageList from '@/components/ImageList.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import Tag from '@/components/Tag.vue';

import { mdiImageMultipleOutline } from '@mdi/js';
import { mapGetters } from 'vuex';
import { PERSON_NOTIFICATION } from '@/utils/faceidNotifications';

export default {
  name: 'ProfileImages',
  components: {
    ImageList,
    RectangleButton,
    Tag,
  },
  props: {
    isToShowSensitiveInformation: { type: Boolean, required: true },
    isLoadingProfile: { type: Boolean, required: true },
    personDisplayStatus: { type: String, required: true },
    personId: { type: String, default: undefined },
    person: { type: Object, default: undefined },
  },
  data() {
    return {
      PERSON_NOTIFICATION,
      icons: {
        imageMultiple: mdiImageMultipleOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      personImages: 'faceid/personImages',
    }),
    isToShowImageList() {
      return this.isLoadingProfile || this.personImages?.length > 0;
    },
    personAbout() {
      return this.person?.about || '';
    },
    personName() {
      return this.person?.name || '';
    },
    personTags() {
      return this.person?.tags || [];
    },
  },
  methods: {
    selectProfileImage(index) {
      this.$emit('selectProfileImage', index);
    },
    goToProfile() {
      this.$emit('goToProfile');
    },
  },
};
</script>
