<template>
  <v-avatar
    class="ma-1 custom-avatar"
    :size="$vuetify.breakpoint.mobile? 80 : 100"
    style="cursor: pointer"
    @click="$emit('onSelectPerson', index)"
  >
    <v-img
      data-dd-privacy="hidden"
      :src="profileImage"
    />
  </v-avatar>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions } from 'vuex';

import { downloadImage } from '@/api';

export default {
  name: 'PeopleListContent',
  props: {
    id: { type: String, required: true },
    index: { type: Number, required: true },
  },
  data() {
    return {
      profileImage: '',
    };
  },
  created() {
    this.fetchPerson(this.id).then((person) => {
      this.downloadProfileImage(person);
    });
  },
  methods: {
    ...mapActions({
      fetchPerson: 'faceid/fetchPerson',
    }),
    downloadProfileImage(person) {
      if (person) {
        const { profile_image_url: profileImageUrl } = person;

        downloadImage(profileImageUrl).then((image) => {
          this.profileImage = image;
        });
      }
    },
  },
};
</script>

<style>
  .custom-avatar {
    border: 1px solid #ccc;
    border-radius: 50%;
  }
</style>
