<template>
  <v-card
    flat
    class="d-flex"
    :style="{
      width: `${imageSize}%`,
      padding: '4px',
    }"
    @click="$emit('set-image', index)"
  >
    <div
      id="image-container"
      :style="{'border-bottom': scoreColor? `6px solid ${scoreColor}`: ''}"
    >
      <v-img
        :src="bestImageLoaded"
        :data-cy="`image-${index}`"
        class="grey lighten-2 d-flex align-end "
        data-dd-privacy="hidden"
        style="flex: 1"
        aspect-ratio="1"
        @load="loadImage"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              size="24"
              indeterminate
              color="grey lighten-1"
            />
          </v-row>
        </template>
        <v-tooltip
          v-if="scoreStatus && !isLoading"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="scoreColor"
              class="d-flex justify-end ma-2"
              :small="$vuetify.breakpoint.mobile"
              v-bind="attrs"
              v-on="on"
            >
              {{ scoreIcon }}
            </v-icon>
          </template>
          <span>{{ scoreMessage }}</span>
        </v-tooltip>
      </v-img>
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import imageResolutionScore, {
  NOT_ACCEPTED_RESOLUTION_IMAGE,
  LOW_RESOLUTION_IMAGE,
  GOOD_RESOLUTION_IMAGE,
} from '@/utils/imageResolutionScore';
import { mapGetters } from 'vuex';

export default {
  name: 'ImageListContent',
  props: {
    img: {
      type: Object,
      required: true,
    },
    index: { type: Number, required: true },
    scoreStatus: Boolean,
  },
  data: () => ({
    scoreIcon: '',
    scoreColor: '',
    scoreMessage: '',
  }),
  computed: {
    ...mapGetters({
      imageIsBeingLoaded: 'faceid/imageIsBeingLoaded',
      personImageInfoByIndex: 'faceid/personImageInfoByIndex',
    }),
    bestImageLoaded() {
      return this.img.originalImage || this.img.mediumImage || this.img.thumbnailImage;
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 100 / 3;
        case 'sm': return 100 / 4;
        case 'md': return 100 / 5;
        case 'lg': return 100 / 6;
        case 'xl': return 100 / 6;
        default: return 100 / 4;
      }
    },
    isLoading() {
      return this.imageIsBeingLoaded(this.index);
    },
    info() {
      return this.personImageInfoByIndex(this.index);
    },
  },
  watch: {
    img() {
      this.validateImage();
    },
    info() {
      this.validateImage();
    },
  },
  methods: {
    loadImage() {
      this.validateImage();
    },
    validateImage() {
      if (this.info?.selected >= 0
      && this.info.faces.length >= 1
      && this.info.faces[this.info.selected]?.image_quality_score >= 0) {
        const inf = this.info.faces[this.info.selected];
        const resolution = imageResolutionScore(inf.bounding_box.width, inf.bounding_box.height);

        if (resolution === NOT_ACCEPTED_RESOLUTION_IMAGE
        || resolution === LOW_RESOLUTION_IMAGE) {
          this.scoreIcon = 'mdi-alert';
          this.scoreColor = this.$vuetify.theme.currentTheme.notAcceptedResolutionColor;
          this.scoreMessage = this.$t('deconve.person.image.resolution.notAccepted');

          if (resolution === LOW_RESOLUTION_IMAGE) {
            this.scoreColor = this.$vuetify.theme.currentTheme.lowResolutionColor;
            this.scoreMessage = this.$t('deconve.person.image.resolution.low');
          }
        } else if (resolution === GOOD_RESOLUTION_IMAGE) {
          this.scoreColor = this.$vuetify.theme.currentTheme.goodResolutionColor;
          this.scoreIcon = 'mdi-check-circle';
          this.scoreMessage = this.$t('deconve.person.image.resolution.good');
        } else {
          this.scoreColor = '';
          this.scoreIcon = '';
          this.scoreMessage = '';
        }
      } else if (this.info?.faces.length > 0 && this.info.selected === undefined) {
        this.scoreColor = 'red';
        this.scoreIcon = 'mdi-alert';
        this.scoreMessage = this.$t('deconve.person.image.noFaceSelected');
      } else {
        this.scoreColor = '';
        this.scoreIcon = '';
        this.scoreMessage = '';
      }
    },
  },
};
</script>

<style scoped>
  #image-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    aspect-ratio: 1;
    overflow: hidden;
  }
</style>
