var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-sm-4 pb-4 pb-sm-4",staticStyle:{"width":"100%","border":"1px solid"},style:({
    borderColor: _vm.$vuetify.theme.themes.light.border,
    backgroundColor: _vm.$vuetify.theme.themes.light.boxBackground
  }),attrs:{"elevation":"0","loading":_vm.isLoading}},[_c('v-row',{staticStyle:{"max-width":"1770px"},style:({ alignItems: _vm.isToShowPersonProfile ? 'flex-end' : 'center' })},[_c('v-col',{class:{'pl-3 pr-1 py-4': !_vm.isMobileVersion},attrs:{"cols":_vm.isMobileVersion ? 12 : 6,"max-width":"885px"}},[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between",class:{'justify-start': _vm.imageSize < 300,
                 'notificationTitle': _vm.isMobileVersion,
                 'mt-4': !_vm.isMobileVersion}},[(_vm.isToShowSensitiveInformation)?_c('div',[(!_vm.isNotificationLoading)?_c('div',{staticClass:"d-flex",class:{'align-center': !_vm.isMobileVersion, 'align-start': _vm.isMobileVersion}},[_c('v-icon',{staticClass:"mr-2 d-sm-flex",attrs:{"color":"textBlack","size":_vm.isMobileVersion ? 20 : 24}},[_vm._v(" "+_vm._s(_vm.icons.videoIcon)+" ")]),_c('h5',{staticClass:"d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text",staticStyle:{"word-break":"break-word"},style:({ 'max-width': _vm.imageSize < 300 ?
                '165px' : 'initial', 'line-height': _vm.titleHeight ? '2' : '1'}),attrs:{"data-dd-privacy":"mask"}},[_vm._v(" "+_vm._s(_vm.videoName)+" ")])],1):_c('div',{staticClass:"d-none d-sm-flex align-center mr-4"},[_c('v-skeleton-loader',{staticClass:"mr-2 d-none d-md-flex",attrs:{"type":"text","width":"100"}})],1),(_vm.isOnDeconveHubWorkspace)?_c('div',{staticClass:"d-flex mt-2 align-center"},[_c('v-icon',{staticClass:"mr-2 d-sm-flex",attrs:{"color":"textBlack","size":_vm.isMobileVersion ? 20 : 24}},[_vm._v(" "+_vm._s(_vm.icons.workspaceIcon)+" ")]),_c('span',{staticClass:"font-weight-medium caption text-sm-subtitle-1 textBlack--text"},[_vm._v(" "+_vm._s(_vm.notificationWorkspaceName)+" ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex mr-2 flex-wrap align-center",class:{'justify-start': _vm.imageSize < 300,
                   'createdDateSubtitle': _vm.isMobileVersion},staticStyle:{"max-height":"24px"}},[(_vm.createdAt)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1 d-sm-flex",attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_vm.icons.calendar)+" ")]),_c('span',{staticClass:"neutral--text font-weight-medium caption text-sm-subtitle-1"},[_vm._v(" "+_vm._s(_vm.createdAt)+" ")])],1):_c('v-skeleton-loader',{staticClass:"mr-3",staticStyle:{"flex":"1"},attrs:{"type":"text","max-width":"100"}})],1)]),_c('carousel',{staticClass:"sideBySide",attrs:{"images":_vm.notificationImages,"index":_vm.index,"profile-image":_vm.profileImage,"number-of-images":_vm.numberOfNotificationImages,"loading":_vm.isNotificationLoading,"is-allowed-save-images":"","show-face-preview":"","card-border-color":"","show-add-to-profile":!_vm.isFromASharedPersonProfile,"carousel-height":'486px'},on:{"saveImage":_vm.saveImage,"downloadImage":_vm.downloadImage,"downloadOriginalImage":_vm.downloadOriginalNotificationImage,"addImageToProfile":_vm.addNotificationImageToPersonProfile,"changeCarouselImageIndex":_vm.setNotificationImageIndex}})],1),(_vm.isNotificationLoading || _vm.isToShowPersonProfile)?_c('v-col',{class:{'pl-1 pr-3 py-4': !_vm.isMobileVersion},attrs:{"cols":_vm.isMobileVersion ? 12 : 6,"max-width":"885px"}},[(_vm.isToShowSensitiveInformation)?_c('div',{staticClass:"d-flex flex-wrap align-center",class:{'justify-start': _vm.imageSize < 300,
                 'profileTitle': _vm.isMobileVersion,
                 'mt-4': !_vm.isMobileVersion}},[(!_vm.isNotificationLoading && _vm.profileImages.length > 0)?_c('div',{staticClass:"d-flex",class:{'mr-4 align-center': !_vm.isMobileVersion, 'align-start': _vm.isMobileVersion}},[_c('v-icon',{staticClass:"mr-2 d-sm-flex",attrs:{"color":"textBlack","size":_vm.isMobileVersion ? 20 : 24}},[_vm._v(" "+_vm._s(_vm.icons.account)+" ")]),_c('h5',{ref:"personNameTitle",staticClass:"d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text",staticStyle:{"word-break":"break-word","line-height":"1"},style:({ 'max-width': _vm.imageSize < 300 ? '165px' : 'initial',
                      'line-height': _vm.isLGSize && _vm.videoNameLength ? '2' : '1' }),attrs:{"data-dd-privacy":"mask"}},[_vm._v(" "+_vm._s(_vm.personName)+" ")])],1):_c('div',{staticClass:"d-none d-sm-flex align-center mr-4"},[_c('v-skeleton-loader',{staticClass:"mr-2 d-none d-md-flex",attrs:{"type":"text","width":"100"}})],1)]):_vm._e(),_c('carousel',{staticClass:"sideBySide",class:{'mt-10': (_vm.isXLSize || _vm.isLGSize) && _vm.videoNameLength},attrs:{"images":_vm.profileImages,"index":_vm.selectedProfileImageIndex,"profile-image":_vm.profileImage,"number-of-images":_vm.profileImages.length,"loading":_vm.isNotificationLoading,"show-face-preview":false,"show-add-to-profile":false,"card-border-color":"","is-profile-carousel":"","carousel-height":'486px'},on:{"downloadOriginalImage":_vm.downloadOriginalProfileImage}})],1):_c('v-col',{staticClass:"d-flex align-center",class:{'pl-1 pr-3 py-4 mb-1': !_vm.isMobileVersion},attrs:{"cols":_vm.isMobileVersion ? 12 : 6,"max-width":"885px"}},[_c('v-card',{staticStyle:{"width":"100%","border":"1px solid"},style:({
          borderColor: _vm.isMobileVersion ? _vm.$vuetify.theme.themes.light.boxBackground
            : _vm.$vuetify.theme.themes.light.border,
          backgroundColor: _vm.$vuetify.theme.themes.light.boxBackground,
          height: _vm.isMobileVersion ? '100%' : '486px'
        }),attrs:{"elevation":"0"}},[(!_vm.isNotificationLoading)?_c('v-row',{staticClass:"fill-height rounded",attrs:{"no-gutters":"","justify":"center","align":"center"}},[(_vm.isNotToShowPersonProfile)?_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"size":"48px","color":"neutral"}},[_vm._v(" mdi-account-alert ")]),_c('div',{staticClass:"text-body-5 neutral--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.collaborativeDatabaseProfile'))+" ")])],1):_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"size":"48px","color":"neutral"}},[_vm._v(" mdi-account-cancel ")]),_c('div',{staticClass:"text-body-5 neutral--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.profileNotFound'))+" ")])],1)],1):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"color":_vm.alertColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":_vm.resetAlert}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }