<template>
  <v-card
    elevation="0"
    class="px-sm-4 pb-4 pb-sm-4"
    :loading="isLoading"
    style="width: 100%; border: 1px solid"
    :style="{
      borderColor: $vuetify.theme.themes.light.border,
      backgroundColor: $vuetify.theme.themes.light.boxBackground
    }"
  >
    <v-row
      style="max-width: 1770px"
      :style="{ alignItems: isToShowPersonProfile ? 'flex-end' : 'center' }"
    >
      <v-col
        :cols="isMobileVersion ? 12 : 6"
        max-width="885px"
        :class="{'pl-3 pr-1 py-4': !isMobileVersion}"
      >
        <div
          class="d-flex flex-wrap align-center justify-space-between"
          :class="{'justify-start': imageSize < 300,
                   'notificationTitle': isMobileVersion,
                   'mt-4': !isMobileVersion}"
        >
          <div v-if="isToShowSensitiveInformation">
            <div
              v-if="!isNotificationLoading"
              class="d-flex"
              :class="{'align-center': !isMobileVersion, 'align-start': isMobileVersion}"
            >
              <v-icon
                class="mr-2 d-sm-flex"
                color="textBlack"
                :size="isMobileVersion ? 20 : 24"
              >
                {{ icons.videoIcon }}
              </v-icon>
              <h5
                class="d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text"
                style="word-break: break-word;"
                :style="{ 'max-width': imageSize < 300 ?
                  '165px' : 'initial', 'line-height': titleHeight ? '2' : '1'}"
                data-dd-privacy="mask"
              >
                {{ videoName }}
              </h5>
            </div>

            <div
              v-else
              class="d-none d-sm-flex align-center mr-4"
            >
              <v-skeleton-loader
                type="text"
                class="mr-2 d-none d-md-flex"
                width="100"
              />
            </div>

            <div
              v-if="isOnDeconveHubWorkspace"
              class="d-flex mt-2 align-center"
            >
              <v-icon
                class="mr-2 d-sm-flex"
                color="textBlack"
                :size="isMobileVersion ? 20 : 24"
              >
                {{ icons.workspaceIcon }}
              </v-icon>

              <span class="font-weight-medium caption text-sm-subtitle-1 textBlack--text">
                {{ notificationWorkspaceName }}
              </span>
            </div>
          </div>

          <div
            class="d-flex mr-2 flex-wrap align-center"
            :class="{'justify-start': imageSize < 300,
                     'createdDateSubtitle': isMobileVersion}"
            style="max-height: 24px;"
          >
            <div
              v-if="createdAt"
              class="d-flex align-center"
            >
              <v-icon
                size="16"
                class="mr-1 d-sm-flex"
              >
                {{ icons.calendar }}
              </v-icon>
              <span class="neutral--text font-weight-medium caption text-sm-subtitle-1">
                {{ createdAt }}
              </span>
            </div>
            <v-skeleton-loader
              v-else
              type="text"
              style="flex: 1"
              max-width="100"
              class="mr-3"
            />
          </div>
        </div>

        <carousel
          class="sideBySide"
          :images="notificationImages"
          :index="index"
          :profile-image="profileImage"
          :number-of-images="numberOfNotificationImages"
          :loading="isNotificationLoading"
          is-allowed-save-images
          show-face-preview
          card-border-color
          :show-add-to-profile="!isFromASharedPersonProfile"
          :carousel-height="'486px'"
          @saveImage="saveImage"
          @downloadImage="downloadImage"
          @downloadOriginalImage="downloadOriginalNotificationImage"
          @addImageToProfile="addNotificationImageToPersonProfile"
          @changeCarouselImageIndex="setNotificationImageIndex"
        />
      </v-col>

      <v-col
        v-if="isNotificationLoading || isToShowPersonProfile"
        :cols="isMobileVersion ? 12 : 6"
        max-width="885px"
        :class="{'pl-1 pr-3 py-4': !isMobileVersion}"
      >
        <div
          v-if="isToShowSensitiveInformation"
          class="d-flex flex-wrap align-center"
          :class="{'justify-start': imageSize < 300,
                   'profileTitle': isMobileVersion,
                   'mt-4': !isMobileVersion}"
        >
          <div
            v-if="!isNotificationLoading && profileImages.length > 0"
            class="d-flex"
            :class="{'mr-4 align-center': !isMobileVersion, 'align-start': isMobileVersion}"
          >
            <v-icon
              class="mr-2 d-sm-flex"
              color="textBlack"
              :size="isMobileVersion ? 20 : 24"
            >
              {{ icons.account }}
            </v-icon>
            <h5
              ref="personNameTitle"
              class="d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text"
              style="word-break: break-word; line-height: 1;"
              :style="{ 'max-width': imageSize < 300 ? '165px' : 'initial',
                        'line-height': isLGSize && videoNameLength ? '2' : '1' }"
              data-dd-privacy="mask"
            >
              {{ personName }}
            </h5>
          </div>
          <div
            v-else
            class="d-none d-sm-flex align-center mr-4"
          >
            <v-skeleton-loader
              type="text"
              class="mr-2 d-none d-md-flex"
              width="100"
            />
          </div>
        </div>
        <carousel
          class="sideBySide"
          :class="{'mt-10': (isXLSize || isLGSize) && videoNameLength}"
          :images="profileImages"
          :index="selectedProfileImageIndex"
          :profile-image="profileImage"
          :number-of-images="profileImages.length"
          :loading="isNotificationLoading"
          :show-face-preview="false"
          :show-add-to-profile="false"
          card-border-color
          is-profile-carousel
          :carousel-height="'486px'"
          @downloadOriginalImage="downloadOriginalProfileImage"
        />
      </v-col>

      <v-col
        v-else
        :cols="isMobileVersion ? 12 : 6"
        max-width="885px"
        :class="{'pl-1 pr-3 py-4 mb-1': !isMobileVersion}"
        class="d-flex align-center"
      >
        <v-card
          elevation="0"
          style="width: 100%; border: 1px solid;"
          :style="{
            borderColor: isMobileVersion ? $vuetify.theme.themes.light.boxBackground
              : $vuetify.theme.themes.light.border,
            backgroundColor: $vuetify.theme.themes.light.boxBackground,
            height: isMobileVersion ? '100%' : '486px'
          }"
        >
          <v-row
            v-if="!isNotificationLoading"
            no-gutters
            class="fill-height rounded"
            justify="center"
            align="center"
          >
            <v-col
              v-if="isNotToShowPersonProfile"
              class="ma-0 pa-0"
              align="center"
              justify="center"
            >
              <v-icon
                size="48px"
                color="neutral"
              >
                mdi-account-alert
              </v-icon>
              <div class="text-body-5 neutral--text">
                {{ $t('deconve.collaborativeDatabaseProfile') }}
              </div>
            </v-col>

            <v-col
              v-else
              class="ma-0 pa-0"
              align="center"
              justify="center"
            >
              <v-icon
                size="48px"
                color="neutral"
              >
                mdi-account-cancel
              </v-icon>
              <div class="text-body-5 neutral--text">
                {{ $t('deconve.profileNotFound') }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-snackbar
        v-model="alert"
        :color="alertColor"
      >
        {{ alertMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="resetAlert"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-card>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapGetters, mapActions } from 'vuex';
import { downloadImage as downloadImageHelper } from '@/api';
import Carousel from '@/components/Carousel.vue';
import {
  mdiPlayBoxMultipleOutline, mdiCalendar, mdiAccountBoxOutline, mdiFolderOutline,
} from '@mdi/js';
import { PERSON_NOTIFICATION } from '@/utils/faceidNotifications';

export default {
  name: 'NotificationImageViewer',
  components: {
    Carousel,
  },
  props: {
    notificationImages: { type: Array, required: true },
    index: { type: Number, required: true },
    numberOfNotificationImages: { type: Number, required: true },
    videoName: { type: String, required: true },
    createdAt: { type: String, required: true },
    personName: { type: String, required: true },
    profileImage: { type: [String, Object], default: undefined },
    profileImageIndex: { type: Number, required: true },
    personDisplayStatus: { type: String, default: PERSON_NOTIFICATION.isAInvalidPersonProfile },
    notificationWorkspaceId: { type: String, default: undefined },
    isFromASharedPersonProfile: { type: Boolean, default: false },
    showFacePreview: Boolean,
    isNotificationLoading: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      PERSON_NOTIFICATION,
      selectedProfileImageIndex: 0,
      alert: false,
      alertMessage: '',
      alertColor: '',
      personNameTitleHeight: 0,
      profileImages: [],
      notificationWorkspaceName: '',
      icons: {
        videoIcon: mdiPlayBoxMultipleOutline,
        calendar: mdiCalendar,
        account: mdiAccountBoxOutline,
        workspaceIcon: mdiFolderOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      personImages: 'faceid/personImages',
      personImagesId: 'faceid/personImagesId',
    }),
    isUserANotificationReviewer() {
      const canReadSuperProfileImages = this.$can('read', 'com.deconve.faceid.superprofile.image');
      const canReadSuperProfileDetails = this.$can('read', 'com.deconve.faceid.superprofile');

      return canReadSuperProfileImages && !canReadSuperProfileDetails;
    },
    isToShowSensitiveInformation() {
      return !this.isUserANotificationReviewer;
    },
    isToShowPersonProfile() {
      return this.personDisplayStatus === PERSON_NOTIFICATION.isToShowPersonProfile;
    },
    isNotToShowPersonProfile() {
      return this.personDisplayStatus === PERSON_NOTIFICATION.isNotToShowPersonProfile;
    },
    profileImagesRef() {
      const profileImages = [...this.personImages];

      return profileImages;
    },
    isLoading() {
      if (this.loading) return true;

      return false;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    videoNameLength() {
      return this.videoName.length > 50;
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 258;
        case 'sm': return 448;
        case 'md': return 516;
        case 'lg': return 516;
        case 'xl': return 612;
        default: return 258;
      }
    },
    isXLSize() {
      return this.$vuetify.breakpoint.name === 'xl';
    },
    isLGSize() {
      return this.$vuetify.breakpoint.name === 'lg';
    },
    titleHeight() {
      return this.personNameTitleHeight > 24 && !this.isLGSize
        && !this.videoNameLength && !this.isMobileVersion;
    },
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
  },
  watch: {
    profileImageIndex() {
      this.selectedProfileImageIndex = this.profileImageIndex;
    },
    profileImagesRef() {
      this.profileImages = this.profileImagesRef;
    },
    notificationWorkspaceId() {
      this.fetchWorkspace(this.notificationWorkspaceId).then((workspace) => {
        this.notificationWorkspaceName = workspace?.name;
      }).catch(() => {
        this.notificationWorkspaceName = '';
      });
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.personNameTitle) {
        this.personNameTitleHeight = this.$refs.personNameTitle.clientHeight;
        clearInterval(interval);
      }
    }, 10);
  },
  methods: {
    ...mapActions({
      fetchNotification: 'faceid/fetchNotification',
      fetchPerson: 'faceid/fetchPerson',
      isImageNameValid: 'faceid/isImageNameValid',
      addImageToProfile: 'faceid/addImageToProfile',
      getOriginalPersonImage: 'faceid/getOriginalPersonImage',
      fetchWorkspace: 'workspace/fetchWorkspace',
    }),
    removeFileNameExtension(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
    },
    setNotificationImageIndex(index) {
      this.$emit('selectedNotificationImageIndex', index);
    },
    downloadImage(index) {
      let notificationImage = this.notificationImages[index];

      const {
        videoNameOriginal,
        faceThumbnail,
        frameThumbnail,
        face,
        frame,
      } = notificationImage;

      // Do not download the image again
      if (faceThumbnail && frameThumbnail && face && frame) return;

      const { notificationId } = this.$route.params;

      this.fetchNotification(notificationId).then((notificationData) => {
        if (notificationData) {
          const { faces } = notificationData;

          // Current image position can change if the notification was updated while we try to read
          // the images
          const newIndex = faces.findIndex(
            (faceInfo) => videoNameOriginal === faceInfo.video_frame.original,
          );

          if (newIndex === index) {
            notificationImage = this.notificationImages[index];
          } else if (newIndex >= 0) {
            this.downloadImage(newIndex);
          } else { // the image was removed
            return;
          }

          const {
            face_image: faceImageInfo,
            video_frame: videoFrameInfo,
          } = notificationData.faces[index];

          const { thumbnail_url: faceThumbnailUrl, original_url: faceOriginalUrl } = faceImageInfo;
          const {
            thumbnail_url: frameThumbnailUrl,
            medium_quality_url: frameMediumQualityUrl,
          } = videoFrameInfo;

          // If the image url is not available, we need to set the notificationImages to let the
          // user to know it
          downloadImageHelper(faceThumbnailUrl).then((image) => {
            Reflect.set(notificationImage, 'faceThumbnail', image);
            this.notificationImages.splice(index, 1, notificationImage);
          });

          downloadImageHelper(frameThumbnailUrl).then((image) => {
            Reflect.set(notificationImage, 'frameThumbnail', image);
            this.notificationImages.splice(index, 1, notificationImage);
          });

          downloadImageHelper(faceOriginalUrl).then((image) => {
            Reflect.set(notificationImage, 'face', image);
            this.notificationImages.splice(index, 1, notificationImage);
          });

          downloadImageHelper(frameMediumQualityUrl).then((image) => {
            // Try to load the original url, if the medium quality frame is not available
            if (image?.length > 0) {
              Reflect.set(notificationImage, 'frame', image);
              this.notificationImages.splice(index, 1, { ...notificationImage, frame: image });
            } else {
              const { original_url: originalVideoFrameUrl } = videoFrameInfo;

              downloadImageHelper(originalVideoFrameUrl).then((originalFrame) => {
                Reflect.set(notificationImage, 'originalFrame', originalFrame);
                Reflect.set(notificationImage, 'frame', image);

                this.notificationImages.splice(
                  index, 1, { ...notificationImage, originalFrame, frame: image },
                );
              });
            }
          });
        }
      });
    },
    downloadOriginalNotificationImage(index) {
      return new Promise((resolve) => {
        const notificationImage = this.notificationImages[index];

        if (!Reflect.has(notificationImage, 'originalFrame')) {
          const { notificationId } = this.$route.params;

          this.fetchNotification(notificationId).then((notificationData) => {
            if (notificationData) {
              const { video_frame: videoFrameInfo } = notificationData.faces[index];
              const { original_url: originalVideoFrameUrl } = videoFrameInfo;

              downloadImageHelper(originalVideoFrameUrl).then((image) => {
                Reflect.set(notificationImage, 'originalFrame', image);
                this.notificationImages.splice(index, 1, notificationImage);
                resolve(image);
              });
            } else {
              resolve(null);
            }
          });
        } else {
          const { originalFrame } = notificationImage;

          resolve(originalFrame);
        }
      });
    },
    downloadOriginalProfileImage(index) {
      return new Promise((resolve) => {
        const profileImage = this.profileImages[index];

        if (!Reflect.has(profileImage, 'originalFrame')) {
          this.fetchPerson(this.personImagesId).then((personData) => {
            if (personData) {
              const { original_url: originalProfileImageFrameUrl } = personData.images[index];

              downloadImageHelper(originalProfileImageFrameUrl).then((image) => {
                Reflect.set(profileImage, 'originalFrame', image);
                this.profileImages.splice(index, 1, profileImage);
                resolve(image);
              });
            } else {
              resolve(null);
            }
          });
        } else {
          const { originalFrame } = profileImage;

          resolve(originalFrame);
        }
      });
    },
    addNotificationImageToPersonProfile(index) {
      const {
        videoNameOriginal: notificationImgName,
        info: notificationInfo,
      } = this.notificationImages[index];

      // Image name without the extension must be unique
      this.isImageNameValid(notificationImgName).then((isValid) => {
        if (isValid) {
          this.downloadOriginalNotificationImage(index).then((originalFrame) => {
            this.addImageToProfile({
              name: notificationImgName,
              frame: originalFrame,
              info: notificationInfo,
              addImageToImageList: true,
            }).then((imageAdded) => {
              if (imageAdded) {
                this.showAlert('primary', this.$t('deconve.person.alerts.personEdited'));
              } else {
                this.showAlert('warn', this.$t('deconve.person.alerts.personEditFailed'));
              }
            });
          });
        } else {
          this.showAlert(
            'info',
            this.$t(
              'deconve.person.alerts.imageAlreadyAdded',
              [this.removeFileNameExtension(notificationImgName)],
            ),
          );
        }
      });
    },
    saveImageWithLink(imageName, image) {
      if (imageName && image) {
        const link = document.createElement('a');

        link.href = image;
        link.download = imageName;

        link.click();
      }
    },
    saveImage(index) {
      const notificationImage = this.notificationImages[index];

      if (!notificationImage?.originalFrame) {
        const { notificationId } = this.$route.params;

        this.fetchNotification(notificationId).then((notificationData) => {
          if (notificationData) {
            const { video_frame: videoFrameInfo } = notificationData.faces[index];
            const {
              original_url: originalVideoFrameUrl,
              original: videoNameOriginal,
            } = videoFrameInfo;

            downloadImageHelper(originalVideoFrameUrl).then((image) => {
              this.saveImageWithLink(videoNameOriginal, image);
            });
          }
        });
      } else {
        const { originalFrame, videoNameOriginal } = notificationImage;

        this.saveImageWithLink(videoNameOriginal, originalFrame);
      }
    },
  },
};
</script>

<style>
.notificationTitle {
  box-sizing: content-box !important;
  width: 100% !important;
  padding-top: 20px;
  padding-left: 16px;
  @media (max-width: 500px) {
    padding-top: 24px;
    padding-left: 24px;
  }
}

.profileTitle {
  box-sizing: content-box !important;
  width: 100% !important;
  padding-top: 20px;
  padding-left: 16px;
  @media (max-width: 500px) {
    padding-top: 4px;
    padding-left: 24px;
  }
}

.createdDateSubtitle {
  box-sizing: content-box !important;
  width: 100% !important;
  padding-top: 8px;
  @media (max-width: 800px) {
    padding-top: 12px;
  }
  @media (max-width: 500px) {
    padding-top: 16px;
  }
}

.sideBySide {
  @media (max-width: 1024px) {
    padding-right: 12px;
    padding-left: 12px;
  }
  @media (max-width: 500px) {
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 12px;
  }
}
</style>
